import React from 'react'
import Incorporate from './Incorporate'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'

const BusinessBreadcrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  

export function BusinessPage() {
    return (
        <>
            <PageTitle breadcrumbs={BusinessBreadcrumbs}>Negócios</PageTitle>
            <Incorporate />
        </>
    )
}