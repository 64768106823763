import { LogTableLead } from "../components/Log";
import { CampObservationLead } from "../components/Observatios";
export function MainInfoLead() {
  return (
    // <MainContainer>
    <div className="card col-md-8">
      <div className='d-flex flex-column p-3'>
        <div className='d-flex flex-column'>
          <div className="d-flex ps-3">
            <ul
              className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8 ">
              <li className="nav-item">
                <a
                  className="nav-link text-active-primary pb-4 active"
                  data-bs-toggle="tab" href="#linha-do-tempo">
                  Linha do Tempo
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link text-active-primary pb-4 "
                  data-bs-toggle="tab"
                  href="#obs" >
                  Observação
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="linha-do-tempo" role="tabpanel" >
            <LogTableLead className="" />
          </div>
        </div >

        <div className="tab-content" id="myTabContent2">
          <div className="tab-pane fade show" id="obs" role="tabpanel" >
            {/* <LogTableLead className="" /> */}
            {/* <CampObservation isUserLoading={false} ids='op' /> */}
            <CampObservationLead isUserLoading={false} />
          </div>
        </div >
      </div>
    </div>
    // </MainContainer>
  )
}