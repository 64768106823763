import React from 'react'

interface InputProps {
  type: string;
  name: string;
  classname: string;
  placeholder: any;
  defautvalue?: string | number | readonly string[] | undefined;
  disabled?: boolean;
  value?: string | number | readonly string[] | undefined;
  onChange?: any
}

export function Input({ type, name, classname, placeholder, defautvalue, disabled, value, onChange }: InputProps) {
  return (
    <input type={type} name={name} className={classname} placeholder={placeholder} defaultValue={defautvalue} disabled={disabled} value={value} onChange={onChange}></input>
  )
}