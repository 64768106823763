import React from 'react'
import { useState, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { StepperComponent } from '../../../assets/ts/components'
import { KTSVG } from '../../../helpers'
import { SideBar } from './SideBar'

type Props = {
  show: boolean
  handleClose: () => void
}


const ClientProspectModal = ({ show, handleClose }: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }


  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        show={show}
        onHide={handleClose}
        onEntered={loadStepper}
        backdrop={true}
      >
        <div className='modal-header d-flex justify-content-end'>
          {/* begin::Close */}
          <button className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => {
            handleClose()
          }}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
          {/* end::Close */}
        </div>
        <div className="row">
          <SideBar />
        </div>
      </Modal>
    </>
  )
}

export { ClientProspectModal }
