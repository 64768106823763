import { useState, useEffect } from 'react'
import { InfoLabel } from '../../../../_crm/InfoLabel'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { getClientById } from '../core/_request'
import { ILead } from '../core/_models'

export function SidebarInfoLead() {
  const intl = useIntl()
  const [dados, setDados] = useState<ILead>()
  const { id } = useParams();
  const value = Number(id?.split(":")[1])

  async function fetchData() {
    const response = await getClientById(value)
    setDados(response)
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (dados) {
    console.log(dados.create_at);
  }

  let date: Date;
  if (dados) {
    date = new Date(dados.create_at)
  } else {

    date = new Date()
  }

  return (
    <div className='card' style={{ width: '32%' }}>
      <div className='d-flex flex-column p-3'>
        <span className="text-gray-800 fw-bolder fs-6 p-4">
          DADOS GERAIS
        </span>

        <div className="p-3">


          <div className='d-flex flex-column'>
            <span className='text-gray-800  fw-bolder p-4'>
              DADOS BÁSICOS
            </span>
            <div className='separator separator-dashed'></div>

            <div className="p-4">
              <div className="d-flex flex-column">

                <InfoLabel
                  title={intl.formatMessage({ id: 'PAGE.TITLE' })}
                  label='Website assinatura retomou projeto out22'
                />

                <InfoLabel
                  title={intl.formatMessage({ id: 'PAGE.CLIENT' })}
                  label={`${dados?.name}`}
                />

                <div className="d-flex flex-row">
                  <InfoLabel
                    title={intl.formatMessage({ id: 'PAGE.COIN' })}
                    label='Real'
                  />

                  <InfoLabel
                    title={intl.formatMessage({ id: 'PAGE.VALUE' })}
                    label='0,00'
                  />
                </div>

                <InfoLabel
                  title={intl.formatMessage({ id: 'PAGE.RESPONSIBLE' })}
                  label={`${dados?.seller?.first_name}`}
                />

                <InfoLabel
                  title={intl.formatMessage({ id: 'PAGE.STATUS' })}
                  label={`${dados?.phase_lead?.phase}`}
                />

                <InfoLabel
                  title={intl.formatMessage({ id: 'PAGE.DATE.CREATION' })}
                  date={date.toLocaleDateString()}
                />

                <InfoLabel
                  title={intl.formatMessage({ id: 'PAGE.MODEL_SALE' })}
                  label='Assinatura de Site'
                />

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}