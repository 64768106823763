import React from 'react'
import {useIntl} from 'react-intl'

const SideBar = () => {
  const intl = useIntl()

  return (
    <>
      <div className='row mb-6'>
        <div className='w-28'>
            <p>DADOS BÁSICOS</p>
            <hr className='my-8 h-px bg-gray-200 border-0 dark:bg-gray-700' />
        </div>
      </div>
    </>
  )
}

export {SideBar}
