import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import * as authHelper from './AuthHelpers'
import { VERIFY_USER_BY_TOKEN, getUserModules, } from './_requests'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { WithChildren } from '../../../../_metronic/helpers'

import { IAuthModel, IUserModel, UserModulesModel, } from './_models'

type AuthContextProps = {
  auth: IAuthModel | undefined
  saveAuth: (auth: IAuthModel | undefined) => void
  currentUser: IUserModel | undefined
  // setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  setCurrentUser: Dispatch<SetStateAction<any>>
  userPermissions: UserModulesModel[] | undefined
  setUserPermissions: Dispatch<SetStateAction<any>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  AuthUserAccess: () => Promise,
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  userPermissions: undefined,
  setUserPermissions: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<IAuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<IUserModel>()
  const [userPermissions, setUserPermissions] = useState<UserModulesModel[]>([])

  const getUser = async () => {
    const requestUser = await VERIFY_USER_BY_TOKEN(String(auth?.api_token))
    const userModel: IUserModel = requestUser.data
    return userModel
  }
  const saveAuth = async (auth: IAuthModel | undefined) => {
    const storageData: any = localStorage.getItem('kt-auth-react-v')
    const storageToken = storageData ? JSON.parse(storageData).token : null

    setAuth(auth)

    if (storageData) {
      const { data } = await VERIFY_USER_BY_TOKEN(storageToken)
      setCurrentUser(data)
      setAuth({ ...auth, api_token: storageToken })
    }
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const getPermissions = async (user: IUserModel) => {
    const request = await getUserModules(Number(user.id))
    const permissions: UserModulesModel[] = request
    setUserPermissions(permissions)
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    localStorage.removeItem('kt-auth-react-v')
  }

  useEffect(() => {
    getUser().then((user) => {
      setCurrentUser(user)
      getPermissions(user)
    })
  }, [])

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, userPermissions, setUserPermissions, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, setUserPermissions } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await VERIFY_USER_BY_TOKEN(apiToken)
          if (data) {
            const user: IUserModel = data
            setCurrentUser(data)
            getUserModules(Number(user.id)).then((permissions) => {
              setUserPermissions(permissions)
            })
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }
      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])
  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
