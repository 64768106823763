interface ModuleProps {
  id: number
  name: string
  role: {
    id: number
    role: string
  }
}
interface verifyPermissionProps {
  module_group: number[]
  inputed_values: { id: number }[]
}

interface getRolePermissionProps {
  array: ModuleProps[]
  role: string
}

export function transformObjectToArray(obj: any) {
  const result = Object.keys(obj).map(key => obj[key])
  return result
}

export function verifyModule({ module_group, inputed_values }: verifyPermissionProps) {
  const modules = inputed_values.map(module => module.id)
  const result = module_group.filter(module => modules.includes(module))
  return result
}

export function getModuleRole({ array, role }: getRolePermissionProps) {
  const permissions = array.filter(module => module.role.role === role)
  return permissions
}


