import React, { FC, useEffect, useState } from 'react'
import { Label } from '../utils/label'
import { useIntl } from 'react-intl';
import { Input } from '../utils/inputs'
import { ILead } from '../_models';
import { getClientById, updatedClient } from '../_requests';
import { useParams } from 'react-router-dom';
import { UsersListLoading } from 'src/app/modules/apps/user-management/users-list/components/loading/UsersListLoading'
import { getStages } from '../../core/_request';
import { IStages } from '../../core/_models';

interface GeneralDateProps {
  classname: string;
  idComponent: string;
  role: string;
}

const GeneralDate: FC<GeneralDateProps> = ({ classname, idComponent, role }) => {
  const intl = useIntl()
  const { id } = useParams();
  const value = Number(id?.split(":")[1])

  const [itens, setItens] = useState<ILead>()
  const [stages, setStages] = useState<IStages[]>()
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)


  async function fetchData() {
    const response = await getClientById(value)
    setItens(response)
  }

  async function getStatesCLient() {
    const response = await getStages()
    setStages(response)
  }

  // envio de formulário 
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true)
    try {
      await updatedClient(value, itens)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      fetchData()
    }
  }

  // captação da mudança 
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setItens({ ...itens, [name]: value })
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    console.log(value)
    setItens({ ...itens, [name]: value })
  }

  useEffect(() => {
    getStatesCLient()
    fetchData()
  }, [])

  let numero = itens?.phone
  let whatsapp = itens?.whatsapp

  if (whatsapp !== '') {
    let whatsappFormated = `(${whatsapp?.substring(0, 2)}) ${whatsapp?.substring(2, 7)}-${whatsapp?.substring(7, 13)}`
    // '(' + whatsapp?.substring(0, 2) + ')' + ' ' + whatsapp?.substring(2, 7) + '-' + whatsapp?.substring(7, 13)
  }
  var phoneFormated = `(${numero?.substring(0, 2)}) ${numero?.substring(2, 7)}-${numero?.substring(7, 13)}`
  // '(' + numero?.substring(0, 2) + ')' + ' ' + numero?.substring(2, 7) + '-' + numero?.substring(7, 13)


  return (
    <div className={classname} id={idComponent} role={role}>
      <div className="card pt-4 mb-6 mb-xl-9">
        <div className='d-flex justify-content-end me-7'>
          {edit == false ? <button className='btn btn-sm btn-primary me-2 my-2' onClick={() => setEdit(true)}>Editar</button> : <button className='btn btn-sm btn-danger me-2 my-2' onClick={() => setEdit(false)}>Cancelar</button>}
        </div>
        <div className="card-body">
          <form className='form' onSubmit={handleSubmit}>
            {loading === true ? <UsersListLoading /> : ''}
            <div className="row mb-12">
              <Label classname='col-lg-4 col-form-label required fw-semibold fs-6' label={intl.formatMessage({ id: 'CLIENTE.RESPONSAVEL' })} />
              <div className="col-lg-8 fv-row">
                <Input
                  type='text'
                  name='seller'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: 'CLIENTE.RESPONSAVEL' })}
                  defautvalue=''
                  value={itens?.seller?.first_name + ' ' + itens?.seller?.last_name}
                  onChange={handleChange}
                  disabled={true}
                />
              </div>
            </div>

            <div className="row mb-6">
              <Label classname='col-lg-4 col-form-label required fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.NOME" })} />
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-12 fw-row">
                    <Input
                      type='text'
                      name='name'
                      onChange={handleChange}
                      classname='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder={intl.formatMessage({ id: "CLIENTE.NOME" })}
                      value={itens?.name}
                      defautvalue=''
                      disabled={edit === true ? false : true} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <Label classname='col-lg-4 col-form-label required fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.EMAIL" })} />
              <div className="col-lg-8 fv-row">
                <Input
                  type='email'
                  name='email'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: "CLIENTE.EMAIL" })}
                  value={itens?.email}
                  onChange={handleChange}
                  disabled={edit === true ? false : true} />
              </div>
            </div>

            <div className="row mb-6">
              <Label classname='col-lg-4 col-form-label required fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.EMAIL_CORPORATIVO" })} />
              <div className="col-lg-8 fv-row">
                <Input
                  type='email'
                  name='emailFinance'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: "CLIENTE.EMAIL_CORPORATIVO" })}
                  defautvalue=''
                  value={itens?.emailFinance}
                  onChange={handleChange}
                  disabled={edit === true ? false : true} />
              </div>
            </div>

            <div className="row mb-6">
              <Label classname='col-lg-4 col-form-label  fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.TELEFONE" })} />

              <div className="col-lg-8">
                <Input
                  type='text'
                  name='phone'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: "CLIENTE.TELEFONE" })}
                  value={phoneFormated}
                  defautvalue=''
                  onChange={handleChange}
                  disabled={edit === true ? false : true} />
              </div>
            </div>

            <div className='row mb-6'>
              <Label classname='col-lg-4 col-form-label required fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.WHATSAPP" })} />

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  name='whatsapp'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: "CLIENTE.WHATSAPP" })}
                  value={itens?.whatsapp}
                  defautvalue=''
                  onChange={handleChange}
                  disabled={edit === true ? false : true} />
              </div>
            </div>

            <div className='row mb-6'>
              <Label classname='col-lg-4 col-form-label required fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.FASE" })} />

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-md'
                  name='phase'
                  value={itens?.phase?.id} onChange={handleSelectChange}
                >
                  <option value={1}>{intl.formatMessage({ id: 'CLIENTE.FASE_LEAD' })}</option>
                  {stages &&
                    stages?.map((stages, index) => (
                      <option key={index} value={stages.id}>
                        {stages.stages}
                      </option>
                    ))}
                </select>
                {/* <Input type='text' name='phase' classname='form-control form-control-lg form-control-solid' placeholder={intl.formatMessage({ id: "CLIENTE.FASE" })} value={itens?.phase?.stages} disabled={true} /> */}
              </div>
            </div>

            <div className='row mb-6'>
              <Label classname='col-lg-4 col-form-label fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.TIPO_CLIENTE" })} />

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  name='cpf_cnpj'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: "CLIENTE.TIPO_CLIENTE" })}
                  value={itens?.cpf_cnpj}
                  disabled={edit === true ? false : true} />
              </div>
            </div>

            <div className='row mb-6'>
              <Label classname='col-lg-4 col-form-label required fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.ATUACAO" })} />

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  name='ocupation_area'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: "CLIENTE.ATUACAO" })}
                  value={itens?.ocupation_area}
                  defautvalue=''
                  onChange={handleChange}
                  disabled={edit === true ? false : true} />
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              {edit === true ? <button className='btn btn-sm btn-success me-2 my-2' type="submit">Salvar</button> : ''}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export { GeneralDate }