import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'

type ListProps = {
    children?: React.ReactNode;
    onDragEnd: (data: any) => void;
    title: string;
    name: string;
}

const List = ({ children, title, onDragEnd, name }: ListProps) => {
    return (
        <div className=' w-96'>
            <h2 className='text-2x1 font-bold mb-2 mx-5'>{title}</h2>
            <div className=''>
                <Droppable droppableId={name}>
                    {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                        <div ref={provided.innerRef} className="h-screen">
                            <div className="p-5 rounded min-h-max bg-secondary mx-2 gap-y-3 flex flex-col h-screen" style={{ width: '22vw' }}>
                                {children}
                                {provided.placeholder}
                            </div>
                        </div>
                    )}
                </Droppable>
            </div>

        </div>
    )
}

export default List;