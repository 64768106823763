import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { KTCard } from '../../../../../_metronic/helpers'
import { ClientsListHeader } from './components/header/ClientsListHeader'
import { ClientsTable } from './table/ClientsTable'
import { ClientEditModal } from './client-edit-modal/ClientEditModal'

const ClientsList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <ClientsListHeader />
        <ClientsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ClientEditModal />}
    </>
  )
}

const ClientListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ClientsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { ClientListWrapper }
