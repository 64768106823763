import { FC } from 'react'
import { IClient } from '../../core/_models'

type Props = {
  lead: IClient
}

const ClientSeller: FC<Props> = ({ lead }) => {

  return (
    <div className='d-flex justify-around me-5'>
      <div className='me-2'>{lead.seller?.first_name + ' ' + lead.seller?.last_name}</div>

    </div>
  )
}

export { ClientSeller }
