import React from 'react'

interface LabelProps {
  classname: string;
  label: React.ReactNode;
}

export function Label({classname, label}: LabelProps){
  return(
    <label className={classname}>{label}</label>
  )
}