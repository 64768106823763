import React from 'react'
import HorizontalNonLinearStepper from './step'

const StatusIndex = () => {
  return (
    <>
      {/* <p>index</p> */}
      <div className='mt-10  overflow-y-hidden pb-3'>
        <HorizontalNonLinearStepper />
      </div>
    </>
  )
}

export {StatusIndex}
