import { useAuth } from "./Auth"
import { transformObjectToArray, verifyModule } from "./_functions"

interface PermissionGateProps {
  children?: any
  user: { modules: number[] }
}

const PermissionGate = ({ children, user }: PermissionGateProps) => {
  const { userPermissions } = useAuth()
  const permissions = transformObjectToArray(userPermissions)

  if (permissions === undefined) return null
  try {
    const permissionValidated = verifyModule({
      module_group: user.modules,
      inputed_values: permissions,
    })

    if (permissionValidated.length > 0) {
      return children
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
    return null
  }
}

export default PermissionGate