import React from 'react'
import { ButtonCrm } from '../../../../_crm/buttons'
import { Tags, LogoContent, Text } from '../../../../_crm/utils'
import { StatusIndex } from './status'
import { updatedClient, updatedPhaseClient } from '../core/_request'
import { useParams } from 'react-router-dom'

const HeaderInfoLead = () => {
  const { id } = useParams()
  const clientId = String(id).replace(":", "")

  async function conquistado() {
    try {
      await updatedClient(Number(clientId), 3)

    } catch (err) {
      console.log(err)
    } finally {
      window.location.reload()
    }
  }

  async function desqualificado() {
    try {
      await updatedPhaseClient(Number(clientId), 1)

    } catch (err) {
      console.log(err)
    } finally {
      window.location.reload()
    }
  }

  return (
    <>
      <div className='card mb-8'>
        <div className='p-4'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex mb-3'>
              <div className='logo'>
                <LogoContent
                  className='bg-light d-flex justify-content-center rounded'
                  Iktsvg='/media/icons/duotune/layouts/lay009.svg'
                  classKtsvg='svg-icon svg-icon-4x text-info'
                />
              </div>

              {/* informações  */}
              <div className='infos ms-3'>
                <Text className='h2 font-bold' label='Adesão' />

                <div className='d-flex'>
                  <Tags
                    className='px-5 bg-light pt-1 rounded-1 d-flex items-center'
                    label='Vendas Alldigital'
                    Iktsvg='/media/icons/duotune/files/fil012.svg'
                    classKtsvg='svg-icon svg-icon-1x me-3 text-info'
                  />

                  <Tags
                    className='px-5 bg-light pt-1 rounded-1 d-flex items-center ms-4'
                    label='R$ 0,00'
                    Iktsvg='/media/icons/duotune/finance/fin010.svg'
                    classKtsvg='svg-icon svg-icon-1x me-3 text-success'
                  />
                </div>
              </div>
            </div>

            {/* botões */}
            <div className=''>
              <ButtonCrm className='btn btn-success me-3' label='Entre em contato' />
              <ButtonCrm className='btn btn-info me-3' onClick={() => conquistado()} label='Conquistar' />
              <ButtonCrm className='btn btn-danger' label='Desqualificar' onClick={() => desqualificado()} />
            </div>
          </div>
        </div>

        {/* status  */}
        <StatusIndex />
      </div>
    </>
  )
}

export { HeaderInfoLead }
