import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { ClientListWrapper } from './clients-list/ClientList'
import { ProfileClient } from 'src/app/pages/clients/ProfileClient'

const ClientBreadcrumbs: Array<PageLink> = [
  {
    title: 'Clientes',
    path: '/crafted/client/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const ClientList = () => {
  return <Routes>
    <Route path='/' element={<ClientListWrapper />} />
    <Route path='/profile:id' element={<ProfileClient />} />
  </Routes>
}

const ClientsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list/*'
          element={
            <>
              <PageTitle breadcrumbs={ClientBreadcrumbs}>Lista de Clientes</PageTitle>
              <ClientList />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='crafted/client' />} />
    </Routes>
  )
}

export default ClientsPage
