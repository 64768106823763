import { FC } from 'react'
import { ILead } from '../../core/_models'

type Props = {
  lead: ILead
}

const LeadSeller: FC<Props> = ({ lead }) => {

  return (
    <div className='d-flex justify-around me-5'>
      <div className='me-2'>{lead.seller?.first_name + ' ' + lead.seller?.last_name}</div>

    </div>
  )
}

export { LeadSeller }
