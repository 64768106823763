import axios, { AxiosResponse } from 'axios'
import { Response } from 'src/_metronic/helpers'
import { IPhaselead, IClientsKamban } from './_models';

const API_CRM_URL = process.env.REACT_APP_CRM_API_URL;
const GET_PHASELEAD = `${API_CRM_URL}/clients/phaseLead`;
const GET_LEAD = `${API_CRM_URL}/clients/getAllByPageKambam`

const getPhaselead = (): Promise<IPhaselead[] | undefined> => {
  return axios
    .get(GET_PHASELEAD)
    .then((response: AxiosResponse<Response<IPhaselead[]>>) => response.data)
    .then((response: Response<IPhaselead[]>) => response.data)
}

const getLeadStatus = (): Promise<IClientsKamban | undefined> => {
  return axios
    .get(`${GET_LEAD}?page=1&items_per_page=10`)
    .then((response: AxiosResponse<Response<IClientsKamban>>) => response.data)
    .then((response: Response<IClientsKamban>) => response.data)
}
export { getPhaselead, getLeadStatus }