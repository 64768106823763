import React from 'react'

interface ButtonProps {
  className: string
  label: string
  onClick?: any
}

export function ButtonCrm({ className, label, onClick }: ButtonProps) {
  return (
    <>
      <button className={className} onClick={onClick}>
        {label}
      </button>
    </>
  )
}
