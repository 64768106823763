import { useListView } from "../../core/ListViewProvider";
import { LeadListToolbar } from "./LeadListToolbar";
import { LeadsListGrouping } from "./LeadListGrouping";
import { LeadListSearchComponent } from "./LeadsListSearchComponent";

const LeadListHeader = () => {
    const { selected } = useListView()
    return (
        <div className='card-header border-0 pt-6'>
        <LeadListSearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {/* begin::Group actions */}
          {selected.length > 0 ? <LeadsListGrouping /> : <LeadListToolbar />}
          {/* end::Group actions */}
        </div>
        {/* end::Card toolbar */}
      </div>
    )
}

export {LeadListHeader}