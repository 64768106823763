import React, { useEffect, useState } from 'react';
import { Label } from '../utils/label'
import { useIntl } from 'react-intl'
import { Input } from '../utils/inputs'
import { ILead } from '../_models'
import { getClientById, updatedClient } from '../_requests';
import { useParams } from 'react-router-dom';
import { UsersListLoading } from 'src/app/modules/apps/user-management/users-list/components/loading/UsersListLoading';

interface DateMarketingProps {
  classname: string;
  idComponent: string;
  role: string;
}

export function DateMarketing({ classname, idComponent, role }: DateMarketingProps) {
  const [itens, setItens] = useState<ILead>()
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const { id } = useParams();
  const value = Number(id?.split(":")[1])
  const intl = useIntl()


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setItens({ ...itens, [name]: value })
  }

  async function fetchData() {
    const response = await getClientById(value)
    setItens(response)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    try {
      await updatedClient(value, itens)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      fetchData()
    }
  }

  useEffect(() => {
    fetchData()
  }, [value])

  return (
    <div className={classname} id={idComponent} role={role}>
      <div className='card pt-4 mb-6 mb-xl-9'>
        <div className='d-flex justify-content-end me-7'>
          {edit == false ? <button className='btn btn-sm btn-primary me-2 my-2' onClick={() => setEdit(true)}>Editar</button> : <button className='btn btn-sm btn-danger me-2 my-2' onClick={() => setEdit(false)}>Cancelar</button>}
        </div>
        <form className='form' onSubmit={handleSubmit}>
          {loading === true ? <UsersListLoading /> : ''}
          <div className='card-body'>
            <div className='row mb-6'>
              <Label classname='col-lg-4 col-form-label  fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.ATUACAO" })} />

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  name='ocipation_area'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: "CLIENTE.ATUACAO" })}
                  value={itens?.ocupation_area}
                  defautvalue=''
                  onChange={handleChange}
                  disabled={edit == true ? false : true} />
              </div>
            </div>

            <div className='row mb-6'>
              <Label classname='col-lg-4 col-form-label  fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.ORIGEN" })} />

              <div className='col-lg-8 fv-row'>
                <a href="/#" className="btn btn-icon btn-instagram me-5 "><i className="fab fa-instagram fs-4"></i></a>
              </div>
            </div>

            <div className='row mb-6'>
              <Label classname='col-lg-4 col-form-label  fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.CAMPANHA" })} />

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  name='campain'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: "CLIENTE.CAMPANHA" })}
                  value={itens?.campain}
                  defautvalue=''
                  onChange={handleChange}
                  disabled={edit == true ? false : true} />
              </div>
            </div>

            <div className='row mb-6'>
              <Label classname='col-lg-4 col-form-label  fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.GRUPO_ADD" })} />

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  name='ad_grup'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: "CLIENTE.GRUPO_ADD" })}
                  value={itens?.ad_grup}
                  defautvalue=''
                  onChange={handleChange}
                  disabled={edit == true ? false : true} />
              </div>
            </div>

            <div className='row mb-6'>
              <Label classname='col-lg-4 col-form-label  fw-semibold fs-6' label={intl.formatMessage({ id: "CLIENTE.ADD" })} />

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  name='announcement'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: "CLIENTE.ADD" })}
                  value={itens?.announcement}
                  defautvalue=''
                  onChange={handleChange}
                  disabled={edit == true ? false : true} />
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              {edit == true ? <button className='btn btn-sm btn-success me-2 my-2' type="submit">Salvar</button> : ''}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}