import PermissionGate from 'src/app/modules/auth/core/PermissionGate'
import { KTSVG, initialQueryState, useDebounce } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useEffect, useState } from 'react'
import { IUser } from '../../core/_models'
import { getSelectedUsers } from 'src/app/modules/apps/lead-management/lead-list/core/_requests'

const ClientsListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const { updateState } = useQueryRequest()
  const [users, setUsers] = useState<IUser[] | undefined>();
  const [selectedUser, setSelectedUser] = useState<number | undefined>();
  const debouncedSearchTerm = useDebounce(String(selectedUser), 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && selectedUser !== undefined) {
      updateState({ sellerId: String(selectedUser), ...initialQueryState })
    }
  },
    [debouncedSearchTerm]
  )


  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  useEffect(() => {
    async function getUsers() {
      const response = await getSelectedUsers()
      setUsers(response)
    }
    getUsers()
  }, [])


  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <ClientsListFilter /> */}

      {/* <button type='button' className='btn btn-light-primary me-3 d-flex'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}

      <PermissionGate user={{ modules: [1] }}>
        <div className="mx-3 py-1">
          <select
            className='form-select form-select-solid form-select-md fs-7'
            defaultValue='Selecionar um usuário'
            onChange={(e) => setSelectedUser(Number(e.target.value))}
          >
            <option>Selecionar por usuário</option>
            {users &&
              users?.map((user, index) => (
                <option key={index} value={String(user.id)}>
                  {user.first_name + ' ' + user.username}
                </option>
              ))}
          </select>
        </div>
      </PermissionGate>

      {/* begin::Add user */}
      <button type='button' className='btn btn-primary d-flex justify-center align-items-center           ' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Adicionar Cliente
      </button>
      {/* end::Add user */}
    </div>
  )
}

export { ClientsListToolbar }
