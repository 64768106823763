import { Column } from "react-table";
import { ClientActionsCell } from './ClientActionsCell'
import { LeadSelectionCell } from './ClientSelectionCell'
import { LeadCustomerHeader } from './ClientCustomerHeader'
import { LeadSelectionHeader } from './ClientSelectionHeader'
import { IClient } from "../../core/_models";
import { LeadInfoPhone } from "./ClientInfoPhone";
import { ClientPhase } from "./ClientPhase";
import { ClientSeller } from "./ClientSeller";

const clientsColumns: ReadonlyArray<Column<IClient>> = [
  {
    Header: (props) => <LeadSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <LeadSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Nome' className='min-w-125px' />,
    accessor: 'name',
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Telefone' className="min-w-80px" />,
    accessor: 'phone',
    Cell: ({ ...props }) => <LeadInfoPhone client={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Email' className="min-w-80px" />,
    accessor: 'email',
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Origem' className="min-w-80px" />,
    accessor: 'origin',
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Fase' className="min-w-80px" />,
    accessor: 'phase',
    Cell: ({ ...props }) => <ClientPhase lead={props.data[props.row.index]} />
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Fase' className="min-w-80px" />,
    accessor: 'seller',
    Cell: ({ ...props }) => <ClientSeller lead={props.data[props.row.index]} />
  },
  {
    Header: (props) => (
      <LeadCustomerHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ClientActionsCell data={props.data[props.row.index]} />,
  },
]

export { clientsColumns }