import React from "react";
import { HeaderInfoLead } from "./header";
import { MainInfoLead } from "./main";
import { SidebarInfoLead } from "./sidebar";
import { useParams } from "react-router-dom";

const InformationLead = () => {
    const { id } = useParams();
    console.log(id)

    return (
        <>
            <HeaderInfoLead />
            <div className="d-flex justify-content-between">
                <SidebarInfoLead />
                <MainInfoLead />
            </div>
        </>
    )
}

export { InformationLead }