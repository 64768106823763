import React, { useEffect, useState } from 'react'
import { KTSVG } from 'src/_metronic/helpers'
import { Dropdown1 } from './dropdown/Dropdown1'
import { IconLog } from './itensLog/item'
import { ILogs } from '../../core/_Models'
import { getLogs } from '../../core/_request'

type Props = {
  className: string
}

const LogTable: React.FC<Props> = ({ className }) => {
  const [dados, setDados] = useState<ILogs[]>()

  async function findLogs() {
    const response = await getLogs()
    if (!response) return null
    setDados(response)
  }

  // const formattedDate = format(
  //   dados,
  //   "'Dia' dd 'de' MMMM', às ' HH:mm'h'"
  // );


  useEffect(() => {
    findLogs()
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-header align-items-center border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Atividades</span>
          <span className='text-muted fw-semibold fs-7'>Ultimas 24h</span>
        </h3>
        {/* meni do log  */}
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div>
      </div>

      <div className='card-body pt-5'>
        <div className='timeline-label'>
          {dados && dados.map((dados, index) => (
            <IconLog className='timeline-item' date={new Date(dados.created_at).toLocaleTimeString()} classIcon={`${dados.action === 'PUT' ? 'text-primary' : 'text-warning'}`} description={dados.description} />
          ))}
        </div>
      </div>
    </div>
  )
}

export { LogTable }
