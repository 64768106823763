import { FC } from 'react'
import { ILead } from '../../core/_models'

type Props = {
  lead: ILead
}

const LeadPhaseStatus: FC<Props> = ({ lead }) => {
  return (
    <div className='d-flex justify-around me-5'>
      <div className='me-2'>{lead.phase_lead?.phase}</div>

    </div>
  )
}

export { LeadPhaseStatus }