
export type ButtonTypes = "primary" | "primary-small" | "primary-icon" | "small-icon" | "small-icon-success" | "primary-md3"

export function SelectButtonType(type: ButtonTypes) {
  switch (type) {
    case 'primary':
      return "btn btn-primary "
    case 'primary-md3':
      return "btn btn-primary col-md-3"
    case 'primary-small':
      return "btn btn-sm btn-primary"
    case 'primary-icon':
      return "btn btn-primary btn-icon"
    case 'small-icon':
      return "btn btn-primary"
    case 'small-icon-success':
      return "btn btn-sm btn-icon "

    default:
      return "btn btn-primary"
  }
}
