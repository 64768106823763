/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import PermissionGate from 'src/app/modules/auth/core/PermissionGate'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to='/error/404'
        icon='/media/icons/duotune/graphs/gra001.svg'
        title='Negócios'
        fontIcon='bi-layers'
      />

      {/* <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub> */}


      <PermissionGate user={{ modules: [1] }}>
        <SidebarMenuItemWithSub
          to='crafted/accounts'
          title='Leads'
          icon='/media/icons/duotune/communication/com005.svg'
          fontIcon='bi-person'
        >
          <SidebarMenuItem to='/crafted/lead/list' title='Listagem de Leads' hasBullet={true} />
          <SidebarMenuItem to='/business' title='card Leads' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </PermissionGate>

      <PermissionGate user={{ modules: [1] }}>
        <SidebarMenuItemWithSub
          to='/crafted/client'
          title='Clientes'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-person'
        >
          <SidebarMenuItem to='/crafted/client/list' title='Listagem de Clientes' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </PermissionGate>

      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Minha conta'
        icon='/media/icons/duotune/communication/com005.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Informações Gerais' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Configurações' hasBullet={true} />
      </SidebarMenuItemWithSub>


      {/* <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

      <PermissionGate user={{ modules: [1] }}>
        <SidebarMenuItemWithSub
          to='/crafted/accounts'
          title='Gerênciamento de Usuário'
          icon='/media/icons/duotune/coding/cod001.svg'
          fontIcon='bi-person'
        >
          <SidebarMenuItem to='/apps/user-management/permissions' title='Permissions' hasBullet={true} />

          <SidebarMenuItemWithSub to='/apps/user-management/roles' title='Roles' hasBullet={true}>
            <SidebarMenuItem to='/apps/user-management/roles' title='Roles List' hasBullet={true} />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/apps/user-management/users'
            title='Users'
            hasBullet={true}
          >
            <SidebarMenuItem to='/apps/user-management/users' title='Usuários' hasBullet={true} />
          </SidebarMenuItemWithSub>
        </SidebarMenuItemWithSub>
      </PermissionGate>



      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export { SidebarMenuMain }
