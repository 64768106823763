import { GeneralDate } from './sections/GeneralDate';
import { ContractDate } from './sections/ContractData';
import { DateMarketing } from './sections/DateMarketing';
import { CampObservation } from './sections/Observation';


export function DataClients() {

    return <>
        <div className='col-md-12 m-5 mt-0'>
            <div className='card pt-9 pb-0 rounded-0 rounded-top '>
                <div className="flex-lg-row-fluid ms-lg-15">
                    <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8 ">
                        <li className="nav-item">
                            <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#dados_gerais"> Dados gerais </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#dados_contato" >
                                Dados do contato
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#marketing" >
                                Dados marketing
                            </a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#contrato" >
                                Contratos
                            </a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#obs" >
                                Observação
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                    {/* dados gerais  */}
                    <GeneralDate classname='tab-pane fade show active' idComponent='dados_gerais' role='tabpanel' />

                    {/* contato  */}
                    <ContractDate classname='tab-pane fade' idComponent='dados_contato' role='tabpanel1' />

                    {/* dados de marketing  */}
                    <DateMarketing classname='tab-pane fade' idComponent='marketing' role='tabpanel' />

                    {/* contratos  */}
                    {/* <div className="tab-pane fade" id="contrato" role="tabpanel">
                        <div className="card pt-4 mb-6 mb-xl-9">

                            <div className="card-body">
                                <div className='row g-5 gx-xxl-8'>
                                    <div className='col-xxl-12'>
                                        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
            <CampObservation isUserLoading={false} ids='obs' />
        </div>
    </>
}