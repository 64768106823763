import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { KTSVG, ID, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteUser } from '../../core/_requests'

type Props = {
  data: {
    id: ID
  }
}

const ClientActionsCell: FC<Props> = (prop: Props) => {
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const querylead = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteItem = useMutation(() => deleteUser(prop.data.id), {
    onSuccess: () => {
      querylead.invalidateQueries([`${QUERIES.CLIENTS_LIST}-${query}`])
    },
  })

  return (
    <>
      <a
        href='/#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Ações
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          {/* <a className='menu-link px-3' onClick={() => console.log(data)}>
              Edit
            </a> */}
          <Link to={`profile:${prop.data.id}`} className='menu-link px-3'>
            Editar
          </Link>
        </div>
      </div>
      {/* end::Menu */}
    </>
  )
}

export { ClientActionsCell }
