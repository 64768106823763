import React from 'react';

export function Dropdown1() {
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
    <div className='px-7 py-5'>
      <div className='fs-5 text-dark fw-bolder'>Filtrar por Período</div>
    </div>

    <div className='separator border-gray-200'></div>

    <div className='px-7 py-5'>
      <div className='mb-10'>
        <label className='form-label fw-bold'>Período:</label>

        <div>
          <select
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            defaultValue={'1'}
          >
            <option></option>
            <option value='1'>3 Meses</option>
            <option value='2'>6 Meses</option>
            <option value='3'>1 Ano</option>
          </select>
        </div>
      </div>

      <div className='mb-10'>
        <label className='form-label fw-bold'>Tipo de serviço:</label>

        <div className='d-flex'>
          <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
            <input className='form-check-input' type='checkbox' value='1' />
            <span className='form-check-label'>Site</span>
          </label>

          <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
            <input className='form-check-input' type='checkbox' value='2' defaultChecked={true} />
            <span className='form-check-label'>Sistema</span>
          </label> 
          <label className='form-check form-check-sm form-check-custom form-check-solid'>
            <input className='form-check-input' type='checkbox' value='2' defaultChecked={true} />
            <span className='form-check-label'>Aplicativo</span>
          </label>
        </div>
      </div>

      <div className='mb-10'>
        <label className='form-label fw-bold'>Status:</label>

        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            value=''
            name='notifications'
            defaultChecked={true}
          />
          <label className='form-check-label'>Ativo</label>
        </div>
      </div>

      <div className='d-flex justify-content-end'>
        <button
          type='reset'
          className='btn btn-sm btn-light btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
        >
          Cancelar
        </button>

        <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
          Aplicar
        </button>
      </div>
    </div>
  </div>
  )
}