import React, { useState } from 'react'
import { IconLog } from 'src/app/modules/accounts/components/widgets/itensLog/item'
import { ILogs } from '../core/_models'
import { getLogsLead } from '../core/_request'

type Props = {
  className: string
}

const LogTableLead: React.FC<Props> = ({ className }) => {
  const [dados, setDados] = useState<ILogs[]>()

  async function findLogsClient() {
    const response = await getLogsLead()
    if (!response) return null
    setDados(response)
  }

  // useEffect(() => {
  //   findLogsClient()
  // }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-header align-items-center border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Atividades</span>
          <span className='text-muted fw-semibold fs-7'>Ultimas 24h</span>
        </h3>
      </div>

      <div className='card-body pt-5'>
        <div className='timeline-label'>
          {dados && dados.map((dados, index) => (
            <IconLog className='timeline-item' date={new Date(dados.created_at).toLocaleTimeString()} classIcon={`${dados.action === 'PUT' ? 'text-primary' : 'text-warning'}`} description={dados.description} />
          ))}
        </div>
      </div>
    </div>
  )
}

export { LogTableLead }