import React, { useEffect, useState } from 'react'
import { useQueryClient, useMutation } from 'react-query'

import { QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { assignSelectedUsers, deleteSelectedUsers, getSelectedUsers } from '../../core/_requests'
import { IUser } from '../../core/_models'

const LeadsListGrouping = () => {
  const queryLead = useQueryClient()
  const { query } = useQueryResponse()
  const { selected, clearSelected } = useListView()
  const [users, setUsers] = useState<IUser[] | undefined>()
  const [selectedUser, setSelectedUser] = useState<number | undefined>()

  const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
    onSuccess: () => {
      queryLead.invalidateQueries([`${QUERIES.CLIENTS_LIST}-${query}`])
      clearSelected()
    },
  })

  const updateSelectedItems = useMutation(() => assignSelectedUsers(selected, Number(selectedUser)), {
    onSuccess: () => {
      queryLead.invalidateQueries([`${QUERIES.CLIENTS_LIST}-${query}`])
      clearSelected()
    },
    onError: () => {
      console.error('error')
    },
  })

  useEffect(() => {
    async function getUsers() {
      const response = await getSelectedUsers()
      setUsers(response)
    }
    getUsers()
  }, [])

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex fw-bolder me-5'>
        <span className='me-2'>{selected.length} </span>Selecionado(s)
      </div>

      <select
        className='form-select form-select-solid form-select-md fs-7'
        defaultValue='1'
        onChange={(e) => setSelectedUser(Number(e.target.value))}
      >
        <option>Atribuir para</option>
        {users &&
          users?.map((user, index) => (
            <option key={index} value={String(user.id)}>
              {user.first_name + ' ' + user.username}
            </option>
          ))}
      </select>

      <button
        type='button'
        className='btn btn-sm btn-primary ms-2'
        onClick={async () => await updateSelectedItems.mutateAsync()}
        disabled={selectedUser === undefined}
      >
        Atribuir
      </button>

      <button
        type='button'
        className='btn btn-sm btn-danger ms-2'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Delete
      </button>
    </div>
  )
}

export { LeadsListGrouping }
