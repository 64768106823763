import axios, { Axios, AxiosResponse } from "axios";
import { ID, Response } from "src/_metronic/helpers";
import { IComments, ILogs, ILead } from "./_models";

const API_CRM_URL = process.env.REACT_APP_CRM_API_URL
const GET_LOG = `${API_CRM_URL}/log/getLogCLient`
const POST_COMMENTS_CLIENT = `${API_CRM_URL}/comments/add`
const GET_CRM_LEADS = `${API_CRM_URL}/clients/getById`
const GET_COMMENTS_CLIENT = `${API_CRM_URL}/comments/getCommentsById`
const UPDATE_CLIENT = `${API_CRM_URL}/clients/updateStageClient`

const getLogsLead = (): Promise<ILogs[] | undefined> => {
  return axios
    .get(`${GET_LOG}`)
    .then((response: AxiosResponse<Response<ILogs[]>>) => response.data)
    .then((response: Response<ILogs[]>) => response.data)
}

const postComments = (comment: IComments, id: ID): Promise<IComments | undefined> => {
  return axios
    .post(`${POST_COMMENTS_CLIENT}?clientId=${id}`, comment)
    .then((response: AxiosResponse<Response<IComments>>) => response.data)
    .then((response: Response<IComments>) => response.data)
}

const getClientById = (id: ID): Promise<ILead | undefined> => {
  return axios
    .get(`${GET_CRM_LEADS}?id=${id}`)
    .then((response: AxiosResponse<Response<ILead>>) => response.data)
    .then((response: Response<ILead>) => response.data)
}

const getComments = (id: string): Promise<IComments[] | undefined> => {
  return axios
    .get(`${GET_COMMENTS_CLIENT}?id=${id}`)
    .then((response: AxiosResponse<Response<IComments[]>>) => response.data)
    .then((response: Response<IComments[]>) => response.data)
}

const updatedClient = (id: ID, stage: any): Promise<ILead | undefined> => {
  return axios
    .put(`${UPDATE_CLIENT}?id=${id}&phase=${stage}`)
    .then((response: AxiosResponse<Response<ILead>>) => response.data)
    .then((response: Response<ILead>) => response.data)
}

const updatedPhaseClient = (id: ID, stage: any): Promise<ILead | undefined> => {
  return axios
    .put(`${UPDATE_CLIENT}?id=${id}&phaseLead=${stage}`)
    .then((response: AxiosResponse<Response<ILead>>) => response.data)
    .then((response: Response<ILead>) => response.data)
}

export { getLogsLead, postComments, getClientById, getComments, updatedClient, updatedPhaseClient }