import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { isNotEmpty } from '../../../../../_metronic/helpers'
import { useFormik } from 'formik'
import { IComments } from '../_models'
import { getComments, postComments } from '../_requests'
import clsx from 'clsx'
import { ClientsListLoading } from '../../../../modules/apps/client-management/clients-list/components/loading/ClientsListLoading'
import { useIntl } from 'react-intl'
import { useListView } from '../../../../modules/apps/client-management/clients-list/core/ListViewProvider'
import { useQueryResponse } from '../../../../modules/apps/client-management/clients-list/core/QueryResponseProvider'
import { useParams } from 'react-router-dom'
import { FeedReplay } from './ObservationReply'

interface ObsProps {
  // classname: string
  isUserLoading: boolean
  ids: string
  // role: string
  comments?: IComments
}
const editObservationSchema = Yup.object().shape({
  comments: Yup.string()
    .min(10, 'Minimum 10 symbols')
    .max(400, 'Maximum 400 symbol')
    .required('Favor preencher este campo'),
})

const CampObservation: FC<ObsProps> = ({ comments, isUserLoading, ids }) => {
  const { id } = useParams()

  const clientId = String(id).replace(":", "")
  const [userComments, setUserComments] = useState<IComments[]>()
  const intl = useIntl()
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [userForEdit] = useState<IComments>({
    ...comments,
    comments: comments?.comments,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
    window.location.reload();
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editObservationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      try {
        if (isNotEmpty(values.comments)) {
          await postComments(values, Number(clientId))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  async function getComentarios() {
    const response = await getComments()
    setUserComments(response)
    console.table(response?.map)
  }


  useEffect(() => {
    getComentarios()
  }, [])

  return (
    <div className='tab-pane fade' id={ids} role='tabpanel'>
      <div className='card pt-4 mb-6 mb-xl-9 rounded-0'>
        <div className='card-body'>
          <div className='col-lg-12 fv-row'>
            <form action='' className='form' onSubmit={formik.handleSubmit} noValidate>
              <textarea
                placeholder='Observação'
                {...formik.getFieldProps('comments')}
                name='comments'
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.comments && formik.errors.comments },
                  {
                    'is-valid': formik.touched.comments && !formik.errors.comments,
                  }
                )}
                rows={5}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.comments && formik.errors.comments && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.comments}</span>
                  </div>
                </div>
              )}
              <div className='d-flex justify-content-end'>
                <button
                  type='submit'
                  className='btn btn-sm btn-primary mt-5'
                  data-kt-users-modal-action='submit'
                  disabled={
                    isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched
                  }
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({ id: 'PAGE.BUTTON.CREATE' })}
                  </span>
                  {(formik.isSubmitting || isUserLoading) && (
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {userComments && userComments?.map((comments, index) => (
        <FeedReplay className='mt-6' role={comments.user?.permissions?.role} avatar={comments.user?.avatar} responseContent={comments.comments} name={`${comments.user?.first_name} ${comments.user?.last_name}`} />
      ))}
      {(formik.isSubmitting || isUserLoading) && <ClientsListLoading />}
    </div>
  )
}

export { CampObservation }
