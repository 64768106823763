import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import { changePassword } from '../core/_requests'

const initialValues = {
  password: '',
  re_password: ''
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(9, 'Minimo 9 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Senha deve conter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial"
    )
    .required('Senha é obrigatório'),
  re_password: Yup.string()
    .min(9, 'Minimo 9 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Senha deve conter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial"
    )
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem')
})

export function NewPassScreen() {
  const { token } = useParams();

  const resetToken = String(token).replace(":", "")

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        changePassword(values.password, resetToken)
          .then(() => {
            setHasErrors(false)
            setLoading(false)
            setStatus('Password changed successfully.')
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Esqueceu a senha ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Digite a sua nova senha de acesso.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Desculpa, parece que há alguns erros detectados, tente novamente.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Enviamos um email com as instruções para redefinir sua senha.
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <input
          type='password'
          placeholder='*******'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.password && formik.errors.password },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}

      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <input
          type='password'
          placeholder='*******'
          autoComplete='off'
          {...formik.getFieldProps('re_password')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.re_password && formik.errors.re_password },
            {
              'is-valid': formik.touched.re_password && !formik.errors.re_password,
            }
          )}
        />
        {formik.touched.re_password && formik.errors.re_password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.re_password}</span>
            </div>
          </div>
        )}

      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        {/* begin::Action */}
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary mr-6'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {/* end::Action */}

        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancelar
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
