import React from 'react'
import { useState } from 'react'
import { useLayout } from '../../../../_metronic/layout/core'
import { ClientProspectModal } from '../../../../_metronic/partials/modals/client-prospect-stepper/ClientProspectModal'
import { ID } from 'src/_metronic/helpers'
import { IStage, IPhaseLead, IUser } from '../../clients/components/_models'

type Props = {
  children?: React.ReactNode
  data: {
    id?: ID,
    user_created?: number,
    user_att?: number,
    seller?: IUser,
    phase?: IStage,
    status?: boolean,
    status_finance?: number,
    block_finance?: number,
    name?: string,
    phone?: string,
    phase_lead?: IPhaseLead,
    ocupation_area?: string,
    email?: string,
    region?: string,
    origin?: string,
    campain?: string,
    ad_grup?: string,
    announcement?: string,
    address?: string,
    address_number?: string,
    city?: string,
    country?: string,
    cep?: string,
    type_people?: number,
    cpf_cnpj?: string,
    corporate_name?: string,
    fantasy_name?: string,
    conversion_date?: Date,
    social_networks?: string,
    update_at?: Date,
    create_at?: Date,
  }
}

const Card = ({ data }: Props) => {
  console.log(data)
  const { config } = useLayout()
  const [showModalClient, setShowModalClient] = useState(false)

  return (
    <>
      <div
        className='d-flex w-100 pe-auto mb-5 shadow'
        onClick={() => setShowModalClient(true)}
      >
        <div className='rounded-start p-3 bg-secondary d-flex' > {/* // falta with-36 do taiwldinf */}
          <img
            src='https://i.pinimg.com/736x/49/a2/52/49a252c6f069e302bdcfc62d23c4ec6f.jpg'
            className="img-fluid rounded"
            style={{ width: '120px', }}
            alt='icone padrão de uten di card'
          />
        </div>

        <main className='py-7 px-5 rounded-end  bg-light w-100'>
          <span className='d-flex row justify-content-between'>
            <h4 className='uppercase font-normal'>{data.corporate_name || 'nome da empresa'}</h4>
            <p>{data.cpf_cnpj}</p>
          </span>
        </main>
      </div>
      <ClientProspectModal
        show={showModalClient}
        handleClose={() => {
          setShowModalClient(false)
        }}
      />
    </>
  )
}

export default Card
