import React from "react";

interface InfoLabelProps {
  title: string;
  label?: string;
  date?: any;
  style?: React.CSSProperties;
}

export function InfoLabel({ title, label, style, date }: InfoLabelProps) {
  return (
    <div className='d-flex flex-column mb-3 me-3' style={style}>
      <span className='fs-5 text-gray-800 text-hover-primary fw-bolder mb-1'>
        {title}
      </span>
      <div className='fs-6 fw-bold text-gray-400'>{label}{date}</div>
    </div>
  )
}