/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty } from '../../../../../../_metronic/helpers'
import { IUser } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { CREATE_CRM_CLIENT } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { generateRandomString } from '../../../../../utils'
import { ClientsListLoading } from '../components/loading/ClientsListLoading'

type Props = {
  isUserLoading: boolean
  user: IUser
}

const editClientSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Fist Name is required'),
  last_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  username: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  phone: Yup.string()
    .min(11, 'Minimum 11 symbols')
    .required('Phone is required'),
  address: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Address is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),

})

const ClientEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [userForEdit] = useState<IUser>({
    ...user,
    first_name: user.first_name,
    last_name: user.last_name,
    username: user.username,
    phone: user.phone,
    address: user.address,
    email: user.email,
    password: user.password,
    role: user.role,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editClientSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      try {
        if (isNotEmpty(values.username)) {
          await CREATE_CRM_CLIENT(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >

          <div className="row">
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Primeiro Nome</label>

              <input
                placeholder='First Name'
                {...formik.getFieldProps('first_name')}
                type='text'
                name='first_name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.first_name && formik.errors.first_name },
                  {
                    'is-valid': formik.touched.first_name && !formik.errors.first_name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.first_name}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Ultimo Nome</label>

              <input
                placeholder='First Name'
                {...formik.getFieldProps('last_name')}
                type='text'
                name='last_name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.last_name && formik.errors.last_name },
                  {
                    'is-valid': formik.touched.last_name && !formik.errors.last_name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.last_name}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className='col-md-7 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Email</label>
              <input
                placeholder='Email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>
            <div className='col-md-5 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Username</label>
              <input
                placeholder='Username'
                {...formik.getFieldProps('username')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.username && formik.errors.username },
                  {
                    'is-valid': formik.touched.username && !formik.errors.username,
                  }
                )}
                type='text'
                name='username'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.username && formik.errors.username && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.username}</span>
                </div>
              )}
            </div>
          </div>

          <div className="fv-row mb-7">
            <label className='form-label fw-bold'>Role</label>

            <div>
              <select
                className='form-select form-select-solid form-select-md'
                {...formik.getFieldProps('role')}
                defaultValue='seller'
              >
                <option>Select an option</option>
                <option value='admin'>Admin</option>
                <option value='manager'>Manager</option>
                <option value='seller'>Seller</option>
              </select>
              {formik.touched.role && formik.errors.role && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.role}</div>
                </div>
              )}
            </div>
          </div>


          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Phone</label>
            <input
              placeholder='Phone'
              {...formik.getFieldProps('phone')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.phone && formik.errors.phone },
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
              )}
              type='email'
              name='phone'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.phone}</span>
              </div>
            )}
          </div>


          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Address</label>
            <input
              placeholder='Address'
              {...formik.getFieldProps('address')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.address && formik.errors.address },
                {
                  'is-valid': formik.touched.address && !formik.errors.address,
                }
              )}
              type='text'
              name='address'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.address}</span>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Password</label>

            <div className="row">
              <div className="col-md-6">


                <input
                  placeholder='Password'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-solid mb-lg-0',
                    { 'is-invalid': formik.touched.password && formik.errors.password },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                  type='texts'
                  name='password'
                  autoComplete='off'
                  disabled={true}
                />

              </div>

              <a className='btn btn-primary col-md-3' onClick={() => {
                const qntRandomString = Math.floor(Math.random() * 12 + 8)
                formik.setFieldValue('password', generateRandomString(qntRandomString))
              }}>Generate</a>

            </div>

            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            )}
          </div>





        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <ClientsListLoading />}
    </>
  )
}

export { ClientEditModalForm }
