import React from 'react'

interface ItemProps {
  className: string;
  classIcon?:string;
  date?: React.ReactNode;
  description?: string
}

export function IconLog({className, classIcon, date, description}:ItemProps) {
  return (
    <div className={className}>
      <div className='timeline-label fw-bold text-gray-800 fs-6'>{date}</div>

      <div className='timeline-badge'>
        <i className={`fa fa-genderless ${classIcon} fs-1`}></i>
      </div>

      <div className='fw-normal timeline-content text-muted ps-3'>
        {description}
      </div>
    </div>
  )
}