import React from 'react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { ProfileClient } from '../../../pages/clients/ProfileClient'
import { LeadListWrapper } from './lead-list/LeadList'
import { InformationLead } from 'src/app/pages/leads/InformationLead'

const LeadBreadcrumbs: Array<PageLink> = [
  {
    title: 'Leaad',
    path: '/crafted/lead/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LeadList = () => {
  return (
    <Routes>
      <Route path='/' element={<LeadListWrapper />} />
      <Route path='/profile:id' element={<InformationLead />} />
    </Routes>
  )
}

const LeadsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list/*'
          element={
            <>
              <PageTitle breadcrumbs={LeadBreadcrumbs}>Lista de Leads</PageTitle>
              <LeadList />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='crafted/lead' />} />
    </Routes>
  )
}

export default LeadsPage
