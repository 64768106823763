import axios from 'axios'
import { IResponse } from '../../../../shared/model/_http'
import { IAuthModel, UserModel, UserModulesModel, } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const API_CRM_URL = process.env.REACT_APP_CRM_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_CRM_URL}/verify_token`
export const GET_USER_BY_TOKEN_URL = `${API_CRM_URL}/users/verify`
export const LOGIN_URL = `${API_CRM_URL}/main/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_RESET_PASSWORD_URL = `${API_CRM_URL}/main/resetpass`
export const REQUEST_CHANGE_PASSWORD_URL = `${API_CRM_URL}/main/changepass`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<IAuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_RESET_PASSWORD_URL, {
    email,
  })
}

export function changePassword(password: string, token: string) {
  return axios.post<{ result: boolean }>(`${REQUEST_CHANGE_PASSWORD_URL}?token=${token}`, {
    password,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export async function VERIFY_USER_BY_TOKEN(token: string) {
  const response = await axios.post<IResponse<IAuthModel>>(`${API_CRM_URL}/users/verify`, {
    token,
  })
  return response.data
}

export async function getUserModules(userId: number): Promise<UserModulesModel[]> {
  const response = await axios.get<UserModulesModel[]>(`${API_CRM_URL}/users/getPermissionById?id=${userId}`)
  return response.data
}




