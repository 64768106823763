import { useEffect, useState } from "react";
import { DataClients } from "./components/DataClients"
import { PageTitle } from "../../../_metronic/layout/core";
import { useParams } from "react-router-dom";
import { getClientById } from "./components/_requests";

export function ProfileClient() {
    const [client, setClient] = useState<any>()
    const { id } = useParams();
    const value = Number(id?.split(":")[1])

    useEffect(() => {
        async function fetchData() {
            const response = await getClientById(value)
            setClient(response)
        }

        fetchData()
    }, [value])


    return <>
        <PageTitle breadcrumbs={[]}>Perfil do Cliente</PageTitle>
        <div className="row">
            <div className='card mb-5 mb-xl-10 col-md-3 ml-1'>
                <div className='card-body pt-9 pb-0 '>

                    <div className='d-flex  mb-3 flex-column justify-content-center'>
                        <div className='d-flex justify-content-center'>
                            <div className=' position-relative symbol-circle mb-6' style={{ maxWidth: 228, width: "100%" }}>
                                <img
                                    src={`https://avatars.dicebear.com/api/initials/${client?.email}.svg`}
                                    alt='Metronic'
                                    style={{ borderRadius: "50%" }}
                                />
                            </div>
                        </div>
                        <div className='flex-grow-1 justify-content-center'>

                            <div className='d-flex justify-content-between flex-column align-items-center flex-wrap mb-2'>
                                <div className='d-flex flex-column m-auto w-100'>
                                    <div className='d-flex flex-wrap justify-content-center fw-bold fs-6 mb-10 pe-2 '>
                                        {client?.status ?
                                            <span className='badge badge-light-success me-2'>Ativo</span> :
                                            <span className='badge badge-light-danger'>Inativo</span>
                                        }
                                        {client?.conversion_date ?
                                            <span className='badge badge-light-primary me-2'>Convertido</span> :
                                            <span className='badge badge-light-warning'>Não Convertido</span>
                                        }
                                    </div>

                                    <div className='d-flex align-items-center mb-6 justify-content-center'>
                                        <span
                                            className='text-gray-800 text-hover-primary fs-2 fw-bolder  text-center'
                                        >{client?.name}</span>
                                    </div>
                                </div>

                                <div className='d-flex flex-wrap justify-content'>
                                    <button
                                        className='btn btn-sm btn-primary me-2 my-2'
                                        id='kt_user_follow_button'
                                    >
                                        Conquistar
                                    </button>
                                    <button
                                        className='btn btn-sm btn-success me-2 my-2'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_offer_a_deal'
                                    >
                                        <a href={`https://wa.me/+55${client?.phone}`} className="text-light">
                                            Entrar em contato
                                        </a>
                                    </button>

                                </div>
                            </div>

                            <div className="d-flex flex-column mt-6">
                                <div className='d-flex flex-column mb-4'>
                                    <span className='fs-5 text-dark fw-bolder'>
                                        Proprietario
                                    </span>
                                    <div className='fs-6 fw-bold text-gray-400'>Padrão</div>
                                </div>

                                <div className='d-flex flex-column mb-4'>
                                    <span className='fs-5 text-dark  fw-bolder'>
                                        Email
                                    </span>
                                    <div className='fs-6 fw-bold text-gray-400'>
                                        <a href={`$mailto:${client?.email}`}
                                            className=''
                                            target={'blank'}>
                                            {client?.email}
                                        </a>
                                    </div>
                                </div>
                                <div className='d-flex flex-column mb-4'>
                                    <span className='fs-5 text-dark fw-bolder'>
                                        Telefone
                                    </span>
                                    <div className='fs-6 fw-bold text-gray-400'>
                                        <a href={`https://api.whatsapp.com/send?phone=55${client?.phone}&text=Olá%20${client?.name}`}>
                                            {client?.phone}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='d-flex flex-wrap flex-stack'>
                                <div className='d-flex flex-column flex-grow-1 pe-8'>
                                    <div className='d-flex  justify-content-center flex-row'>
                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4  mb-3 '>
                                            <div className='d-flex align-items-center '>
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr066.svg'
                                                    className='svg-icon-3 svg-icon-success me-2'
                                                />
                                                <div className='fs-2 fw-bolder'>R$ 850</div>
                                            </div>

                                            <div className='fw-bold fs-6 text-gray-400'>Lucro</div>
                                        </div>

                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4  mb-3 '>
                                            <div className='d-flex align-items-center'>
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr065.svg'
                                                    className='svg-icon-3 svg-icon-danger me-2'
                                                />
                                                <div className='fs-2 fw-bolder'>115</div>
                                            </div>

                                            <div className='fw-bold fs-6 text-gray-400'>Leads</div>
                                        </div>

                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <DataClients />
            </div>
        </div>
    </>
}