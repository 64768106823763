import React, { useEffect, useState } from 'react'
import { Label } from '../utils/label'
import { useIntl } from 'react-intl'
import { Input } from '../utils/inputs'
import { Provider, IStates, ILead } from '../_models'
import { useParams } from 'react-router-dom'
import { getClientById, updatedClient } from '../_requests'
import { UsersListLoading } from 'src/app/modules/apps/user-management/users-list/components/loading/UsersListLoading'

interface ContractDateProps {
  classname: string
  idComponent: string
  role: string
}

export function ContractDate({ classname, idComponent, role }: ContractDateProps) {
  const [phones, setPhones] = useState<Provider[]>([])
  const [states, setStates] = useState<IStates[]>([])
  const [itens, setItens] = useState<ILead>()
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const { id } = useParams();
  const value = Number(id?.split(":")[1])

  const addInputPhone = (e: any) => {
    e.preventDefault()
    setPhones([...phones, e])
  }

  const fetchStates = async () => {
    const result = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(response => response.json())
      .then(data => data)

    setStates(result)
  }

  async function fetchData() {
    const response = await getClientById(value)
    setItens(response)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    try {
      await updatedClient(value, itens)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      fetchData()
    }
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    console.log(value)
    setItens({ ...itens, [name]: value })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setItens({ ...itens, [name]: value })
  }

  useEffect(() => {
    fetchStates()
    fetchData()
  }, [])



  const intl = useIntl()
  return (
    <div className={classname} id={idComponent} role={role}>
      <div className='card pt-4 mb-6 mb-xl-9'>
        <div className='d-flex justify-content-end me-7'>
          {edit == false ? <button className='btn btn-sm btn-primary me-2 my-2' onClick={() => setEdit(true)}>Editar</button> : <button className='btn btn-sm btn-danger me-2 my-2' onClick={() => setEdit(false)}>Cancelar</button>}
        </div>
        <form className='form' onSubmit={handleSubmit}>
          {loading === true ? <UsersListLoading /> : ''}
          <div className='card-body'>
            <div className='row mb-6'>
              <Label
                classname='col-lg-4 col-form-label required fw-semibold fs-6'
                label={intl.formatMessage({ id: 'CLIENTE.RAZAO_SOCIAL' })}
              />

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  name='corporate_name'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: 'CLIENTE.RAZAO_SOCIAL' })}
                  value={itens?.corporate_name}
                  onChange={handleChange}
                  defautvalue=''
                  disabled={edit == true ? false : true}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <Label
                classname='col-lg-4 col-form-label required fw-semibold fs-6'
                label={intl.formatMessage({ id: 'CLIENTE.NOME_FANTASIA' })}
              />

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  name='fantasy_name'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: 'CLIENTE.NOME_FANTASIA' })}
                  value={itens?.fantasy_name}
                  defautvalue=''
                  onChange={handleChange}
                  disabled={edit == true ? false : true}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <Label
                classname='col-lg-4 col-form-label required fw-semibold fs-6'
                label={intl.formatMessage({ id: 'CLIENTE.EMAIL' })}
              />

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  name='email'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: 'CLIENTE.EMAIL' })}
                  value={itens?.email}
                  defautvalue=''
                  onChange={handleChange}
                  disabled={edit == true ? false : true}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <Label
                classname='col-lg-4 col-form-label required fw-semibold fs-6'
                label={intl.formatMessage({ id: 'CLIENTE.EMAIL_CORPORATIVO' })}
              />

              <div className='col-lg-8 fv-row'>
                <Input
                  type='emailFinance'
                  name='email_finance'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: 'CLIENTE.EMAIL_CORPORATIVO' })}
                  value={itens?.emailFinance}
                  defautvalue=''
                  onChange={handleChange}
                  disabled={edit == true ? false : true}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <Label
                classname='col-lg-4 col-form-label required fw-semibold fs-6'
                label={intl.formatMessage({ id: 'CLIENTE.TELEFONE' })}
              />

              <div className='col-lg-8 fv-row d-flex'>
                <Input
                  type='text'
                  name='phone'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: 'CLIENTE.TELEFONE' })}
                  value={itens?.phone}
                  defautvalue=''
                  onChange={handleChange}
                  disabled={edit == true ? false : true}
                />
                {/* <button onClick={addInputPhone} className='btn btn-icon btn-success'>
                  <i className='bi bi-plus-circle'></i>
                </button> */}
              </div>
            </div>
            {phones.map((phone, index) => (
              <div className='row mb-6' key={index}>
                <label className='col-lg-4 col-form-label required fw-semibold fs-6'>
                  {intl.formatMessage({ id: 'CLIENTE.TELEFONE' })}
                </label>

                <div className='col-lg-8 fv-row d-flex'>
                  <input
                    type='text'
                    name='phoneadd[]'
                    id='newInput'
                    className='form-control form-control-lg form-control-solid me-4'
                    placeholder={intl.formatMessage({ id: 'CLIENTE.TELEFONE' })}
                    defaultValue=''
                  />
                  <button className='btn btn-icon btn-danger'>
                    <i className='bi bi-x-circle'></i>{' '}
                  </button>
                </div>
              </div>
            ))}

            <div className='row mb-6'>
              <Label
                classname='col-lg-4 col-form-label required fw-semibold fs-6'
                label={intl.formatMessage({ id: 'CLIENTE.WHATSAPP' })}
              />

              <div className='col-lg-8 fv-row'>
                <Input
                  type='text'
                  name='whatsapp'
                  classname='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({ id: 'CLIENTE.WHATSAPP' })}
                  value={itens?.whatsapp}
                  defautvalue=''
                  onChange={handleChange}
                  disabled={edit == true ? false : true}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-semibold fs-6'>
                {intl.formatMessage({ id: 'CLIENTE.REGIAO' })}
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-md'
                  name='region'
                  value={itens?.region} onChange={handleSelectChange}
                >
                  <option value={1}>{intl.formatMessage({ id: 'CLIENTE.REGIAO' })}</option>
                  {states &&
                    states?.map((states, index) => (
                      <option key={index} value={states.nome}>
                        {states.nome}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              {edit == true ? <button className='btn btn-sm btn-success me-2 my-2' type="submit">Salvar</button> : ''}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
