
import axios, { AxiosResponse } from "axios";
import { Response } from "src/_metronic/helpers";
import { ILogs, IUser } from "./_Models";
import { IUpdateEmail, IUpdatedPassword } from '../components/settings/SettingsModel';

const API_CRM_URL = process.env.REACT_APP_CRM_API_URL
const GET_INFO_USER = `${API_CRM_URL}/users/getInfoUsers`
const GET_LOG = `${API_CRM_URL}/log/getAll`
const UPDATED_USER = `${API_CRM_URL}/users/update`
const UPDATED_EMAIL = `${API_CRM_URL}/users/updateEmailUser`
const UPDATED_PASSWORD = `${API_CRM_URL}/users/updatePasswordUser`

const getInfoUser = (): Promise<IUser | undefined> => {
  return axios
    .get(`${GET_INFO_USER}`)
    .then((response: AxiosResponse<Response<IUser>>) => response.data)
    .then((response: Response<IUser>) => response.data)
}

const getLogs = (): Promise<ILogs[] | undefined> => {
  return axios
    .get(`${GET_LOG}`)
    .then((response: AxiosResponse<Response<ILogs[]>>) => response.data)
    .then((response: Response<ILogs[]>) => response.data)
}

const updatedLogedUser = (user: IUser): Promise<IUser | undefined> => {
  return axios
    .put(UPDATED_USER, user)
    .then((response: AxiosResponse<Response<IUser>>) => response.data)
    .then((response: Response<IUser>) => response.data)
}

const updateEmailUser = (user: IUpdateEmail): Promise<IUpdateEmail | undefined> => {
  return axios
    .put(UPDATED_EMAIL, user)
    .then((response: AxiosResponse<Response<IUpdateEmail>>) => response.data)
    .then((response: Response<IUpdateEmail>) => response.data)
}

const updatedPasswordUser = (user: IUpdatedPassword): Promise<IUpdatedPassword | undefined> => {
  return axios
    .put(UPDATED_PASSWORD, user)
    .then((response: AxiosResponse<Response<IUpdatedPassword>>) => response.data)
    .then((response: Response<IUpdatedPassword>) => response.data)
}

export { getInfoUser, updatedLogedUser, updateEmailUser, updatedPasswordUser, getLogs }