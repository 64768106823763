import { Column } from "react-table";
import { LeadActionsCell } from './LeadActionsCell'
import { LeadSelectionCell } from './LeadSelectionCell'
import { LeadCustomerHeader } from './LeadCustomerHeader'
import { LeadSelectionHeader } from './LeadSelectionHeader'
import { ILead } from "../../core/_models";
import { LeadInfoPhone } from "./LeadInfoPhone";
import { LeadPhase } from "./LeadPhase";
import { LeadSeller } from "./LeadSeller";
import { LeadPhaseStatus } from './LeadPhaseStatus'

const leadsColumns: ReadonlyArray<Column<ILead>> = [
  {
    Header: (props) => <LeadSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <LeadSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Nome da empresa' className='min-w-125px' />,
    accessor: 'corporate_name',
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Nome' className='min-w-125px' />,
    accessor: 'name',
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Responsável' className="min-w-80px" />,
    accessor: 'seller',
    Cell: ({ ...props }) => <LeadSeller lead={props.data[props.row.index]} />
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Telefone' className="min-w-80px" />,
    accessor: 'phone',
    Cell: ({ ...props }) => <LeadInfoPhone lead={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Email' className="min-w-80px" />,
    accessor: 'email',
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Origem' className="min-w-80px" />,
    accessor: 'origin',
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Fase' className="min-w-80px" />,
    accessor: 'phase',
    Cell: ({ ...props }) => <LeadPhase lead={props.data[props.row.index]} />
  },
  {
    Header: (props) => <LeadCustomerHeader tableProps={props} title='Fase' className="min-w-80px" />,
    accessor: 'phase_lead',
    Cell: ({ ...props }) => <LeadPhaseStatus lead={props.data[props.row.index]
    } />
  },
  {
    Header: (props) => (
      <LeadCustomerHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <LeadActionsCell data={props.data[props.row.index]} />,
  },
]

export { leadsColumns }