import React from 'react'
import { KTSVG } from '../../_metronic/helpers'

interface PriceProps {
  className: string;
  label: string;
  Iktsvg: string;
  classKtsvg: string;
}

interface LogoContentProps {
  className: string;
  Iktsvg: string;
  classKtsvg: string;
}

interface textProps {
  className: string;
  label: string
}

const Tags = ({ className, label, Iktsvg, classKtsvg }: PriceProps) => {
  return (
    <>
      <div className={className}>
        <KTSVG path={Iktsvg} className={classKtsvg} />
        <p className='mb-0'>{label}</p>
      </div>
    </>
  )
}

const LogoContent = ({ className, Iktsvg, classKtsvg }: LogoContentProps) => {
  return (
    <>
      <div className={className}>
        <div className='p-1'>
          <KTSVG
            path={Iktsvg}
            className={classKtsvg}
          />
        </div>
      </div>
    </>
  )
}

const Text = ({ className, label }: textProps) => {
  return <p className={className}>{label}</p>
}

export { Tags, LogoContent, Text }
