import React from 'react'
import { KTSVG } from 'src/_metronic/helpers'

type Props = {
  className: string
  avatar?: React.ReactNode
  role?: React.ReactNode
  responseContent?: React.ReactNode
  name?: React.ReactNode
}

const FeedReplay: React.FC<Props> = ({ className, avatar, role, responseContent, name }) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-body pb-0'>
        {/* begin::Header */}
        <div className='d-flex align-items-center mb-5'>
          {/* begin::User */}
          <div className='d-flex align-items-center flex-grow-1'>
            {/* begin::Avatar */}
            <div className='symbol symbol-45px me-5'>
              <img src={`${avatar}`} alt='' />
            </div>
            {/* end::Avatar */}

            {/* begin::Info */}
            <div className='d-flex flex-column'>
              <p className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                {name}
              </p>

              <span className='text-gray-400 fw-semibold'>{role}</span>
            </div>
            {/* end::Info */}
          </div>
          {/* end::User */}
        </div>
        {/* end::Header */}

        {/* begin::Post */}
        <div className='mb-5'>
          {/* begin::Text */}
          <p className='text-gray-800 fw-normal mb-5'>
            {responseContent}
          </p>
          {/* end::Text */}

          {/* begin::Toolbar */}
          {/* <div className='d-flex align-items-center mb-5'>
            <a
              href='#'
              className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
            >
              <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />0
            </a>

            <a
              href='#'
              className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
            >
              <KTSVG path='/media/icons/duotune/general/gen030.svg' className='svg-icon-2' />3
            </a>
          </div> */}
          {/* end::Toolbar */}
        </div>
        {/* end::Post */}

        {/* begin::Separator */}
        <div className='separator mb-4'></div>
        {/* end::Separator */}

        {/* begin::Reply input */}
        <form className='position-relative mb-6'>
          <textarea
            className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
            rows={1}
            placeholder='Responder...'
          ></textarea>

          <div className='position-absolute top-0 end-0 me-n5'>
            {/* <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
              <KTSVG
                path='/media/icons/duotune/communication/com008.svg'
                className='svg-icon-3 mb-3'
              />
            </span> */}

            <span className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
              <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-3 mb-3' />
            </span>
          </div>
        </form>
        {/* edit::Reply input */}
      </div>
    </div>
  )
}

export { FeedReplay }