import axios, { AxiosResponse } from 'axios'
import { Response } from 'src/_metronic/helpers';
import { IStages, IUser } from './_models';

const API_CRM_URL = process.env.REACT_APP_CRM_API_URL;
const GET_STAGES = `${API_CRM_URL}/main/getStages`;
const GET_USERES = `${API_CRM_URL}/main/getStages`;

const getStages = (): Promise<IStages[] | undefined> => {
  return axios
    .get(GET_STAGES)
    .then((response: AxiosResponse<Response<IStages[]>>) => response.data)
    .then((response: Response<IStages[]>) => response.data)
}

const getUsers = (): Promise<IUser[] | undefined> => {
  return axios
    .get(GET_USERES)
    .then((response: AxiosResponse<Response<IUser[]>>) => response.data)
    .then((response: Response<IUser[]>) => response.data)
}

export { getStages, getUsers }