import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../_metronic/helpers'
import { IComments, ILead } from './_models'



const API_CRM_URL = process.env.REACT_APP_CRM_API_URL
const GET_CRM_USERS = `${API_CRM_URL}/clients/getById`
const POST_COMMENTS_CLIENT = `${API_CRM_URL}/comments/add`
const GET_COMMENTS_CLIENT = `${API_CRM_URL}/comments/getAll`
const UPDATE_CLIENT = `${API_CRM_URL}/clients/update`

const getClientById = (id: ID): Promise<ILead | undefined> => {
  return axios
    .get(`${GET_CRM_USERS}?id=${id}`)
    .then((response: AxiosResponse<Response<ILead>>) => response.data)
    .then((response: Response<ILead>) => response.data)
}

const getComments = (): Promise<IComments[] | undefined> => {
  return axios
    .get(`${GET_COMMENTS_CLIENT}`)
    .then((response: AxiosResponse<Response<IComments[]>>) => response.data)
    .then((response: Response<IComments[]>) => response.data)
}

const updatedClient = (id: ID, client: any): Promise<ILead | undefined> => {
  return axios
    .put(`${UPDATE_CLIENT}?id=${id}`, client)
    .then((response: AxiosResponse<Response<ILead>>) => response.data)
    .then((response: Response<ILead>) => response.data)
}



const postComments = (comment: IComments, id: ID): Promise<IComments | undefined> => {
  return axios
    .post(`${POST_COMMENTS_CLIENT}?clientId=${id}`, comment)
    .then((response: AxiosResponse<Response<IComments>>) => response.data)
    .then((response: Response<IComments>) => response.data)
}


export { getClientById, postComments, getComments, updatedClient }
