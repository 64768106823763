import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { IUser, User, ClientQueryResponse } from './_models'

const API_CRM_URL = process.env.REACT_APP_CRM_API_URL
const POST_CRM_CLIENT_URL = `${API_CRM_URL}/cadUsers`
const GET_CRM_CLIENTS_URL = `${API_CRM_URL}/clients/getAllByPageClients`

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const CREATE_CRM_CLIENT = (user: IUser): Promise<IUser | undefined> => {
  return axios
    .post(POST_CRM_CLIENT_URL, user,)
    .then((response: AxiosResponse<Response<IUser>>) => response.data)
    .then((response: Response<IUser>) => response.data)
}

const GET_CRM_CLIENTS = (query: string): Promise<ClientQueryResponse> => {
  return axios
    .get(`${GET_CRM_CLIENTS_URL}?${query}`)
    .then((response: AxiosResponse<ClientQueryResponse>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => { })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export { GET_CRM_CLIENTS, CREATE_CRM_CLIENT, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser }
