import {FC} from 'react'
import {ILead} from '../../core/_models'

type Props = {
  lead: ILead
}

const LeadInfoPhone: FC<Props> = ({lead}) => {
  let numero = lead.phone
  let length = numero?.length
  let phoneFormated

  if (length === 14) {
    phoneFormated =
      '(' +
      numero?.substring(3, 5) +
      ')' +
      ' ' +
      numero?.substring(5, 9) +
      '-' +
      numero?.substring(10, 14)
  }

  if (length === 13) {
    phoneFormated =
      '(' +
      numero?.substring(3, 5) +
      ')' +
      ' ' +
      numero?.substring(5, 9) +
      '-' +
      numero?.substring(9, 13)
  }

  return (
    <div className='d-flex justify-around me-5'>
      <div className='me-2'>{phoneFormated ? phoneFormated: '(00) 0000-0000'}</div>
      <div className=''>
        <a href={`https://wa.me/${lead.phone}`} target='_blank' rel="noreferrer">
          <i className='bi bi-whatsapp text-success fs-4'></i>
        </a>
      </div>
    </div>
  )
}

export {LeadInfoPhone}
