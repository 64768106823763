import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { KTCard } from '../../../../../_metronic/helpers'
import { LeadListHeader } from './components/header/LeadListHeader'
import { LeadsTable } from './table/LeadsTable'
import { LeadEditModal } from './lead-edit-modal/LeadEditModal'

const LeadList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <LeadListHeader />
        <LeadsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <LeadEditModal />}
    </>
  )
}

const LeadListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <LeadList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { LeadListWrapper }