import Card from "../Card";
import List from "../List";
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { useEffect, useState } from 'react'
import { IClientsKamban, IPhaselead } from "../core/_models";
import { getLeadStatus, getPhaselead } from "../core/_request";

const Incorporate = () => {
    const [phaseLlead, setPhaselead] = useState<IPhaselead[]>()
    const [clients, setClients] = useState<IClientsKamban>()

    async function getStatusLead() { // tras a lista dos cards
        const response = await getPhaselead()
        setPhaselead(response)
    }
    const [items, setItems] = useState(clients);


    async function getClients() {
        const response = await getLeadStatus()
        setClients(response)

    }

    const removeFromList = (list: any, index: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(index, 1);
        return [removed, result]
    };

    const addToList = (list: any, index: any, element: any) => {
        const result = Array.from(list);
        result.splice(index, 0, element);
        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        // console.log({ ...clients })

        const listCopy: any = { ...clients };
        console.log(listCopy)
        const sourceList = listCopy[result.source.droppableId];
        const [removedElement, newSourceList] = removeFromList(
            sourceList,
            result.source.index);
        listCopy[result.source.droppableId] = newSourceList;

        const destinationList = listCopy[result.destination.droppableId];
        listCopy[result.destination.droppableId] = addToList(
            destinationList,
            result.destination.index,
            removedElement
        );
        setItems(listCopy);

    };

    useEffect(() => {
        getStatusLead()
        getClients()
    }, [])

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="d-flex p-5">
                    <div className="d-flex p-5 overflow-scroll w-1">
                        <List title="Contato Inicial" onDragEnd={onDragEnd} name="ContatoInicial">
                            {clients?.contatoInicial.map((item, index) => (
                                <Card data={item} />
                            ))}
                        </List>

                        <List title="Demonstrou Interesse" onDragEnd={onDragEnd} name="DemonstrouInteresse">
                            {clients?.demonstrouInteresse.map((item, index) => (
                                <Card data={item} />
                            ))}
                        </List>

                        <List title="Negociação em Andamento" onDragEnd={onDragEnd} name="NegociaçaoemAndamento">
                            {clients?.negocAndamento.map((item, index) => (
                                <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
                                    {(provided, snapshot) => (
                                        <div id='assigned'>
                                            <div className="teste"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Card data={item} />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </List>

                        <List title="Aguardando Retorno" onDragEnd={onDragEnd} name="AguardandoRetorno">
                            {clients?.agardRetorno.map((item, index) => (
                                <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
                                    {(provided, snapshot) => (
                                        <div id='assigned'>
                                            <div className="teste"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Card data={item} />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </List>

                        <List title="Conquistado" onDragEnd={onDragEnd} name="Conquistado">
                            {clients?.consquistado.map((item, index) => (
                                <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
                                    {(provided, snapshot) => (
                                        <div id='assigned'>
                                            <div className="teste"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Card data={item} />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </List>
                    </div>
                </div>
            </DragDropContext>
        </>
    );
};

export default Incorporate;