import React from 'react'
import { ButtonTypes, SelectButtonType } from "./_function";
export type PermissionType = "EDITAR" | "VISUALIZAR"

interface ButtonProps {
  children?: React.ReactNode;
  value?: string;
  buttonType: ButtonTypes;
  onClick?: () => void;
  permissions?: PermissionType[];
}

export function Button({ children, value, buttonType, onClick, permissions }: ButtonProps) {

  const hasPermission = (permission: PermissionType) => {
    if (!permissions) return true;
    return permissions.includes(permission);
  }

  const permissionGroup = {
    EDITAR: hasPermission("EDITAR"),
    VISUALIZAR: hasPermission("VISUALIZAR"),
  }

  return (
    <button
      disabled={!permissionGroup.EDITAR}
      className={SelectButtonType(buttonType)}
      onClick={!permissionGroup.EDITAR ? undefined : onClick}
    >
      {children ? children : value}
    </button>
  );
}