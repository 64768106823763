import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { LeadQueryResponse, ILead, IUser, IPhaselead, IComments } from './_models'

const API_CRM_URL = process.env.REACT_APP_CRM_API_URL
const POST_CRM_LEAD_URL = `${API_CRM_URL}/clients/create`
const POST_COMMENTS_CLIENT = `${API_CRM_URL}/comments/add`
const GET_CRM_CLIENTS_URL = `${API_CRM_URL}/clients/getAllByPage`
const GET_PHASE_LEADS = `${API_CRM_URL}/clients/phaseLead`

const GET_USERS_URL = `${API_CRM_URL}/users/getAll`

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`

const CREATE_CRM_LEAD = (lead: ILead): Promise<ILead | undefined> => {
  return axios
    .post(POST_CRM_LEAD_URL, lead)
    .then((response: AxiosResponse<Response<ILead>>) => response.data)
    .then((response: Response<ILead>) => response.data)
}

const POST_COMMENTS = (comment: IComments): Promise<IComments | undefined> => {
  return axios
    .post(POST_COMMENTS_CLIENT, comment)
    .then((response: AxiosResponse<Response<IComments>>) => response.data)
    .then((response: Response<IComments>) => response.data)
}

const GET_CRM_CLIENTS = (query: string, sellerId?: number): Promise<LeadQueryResponse> => {
  if (!sellerId) return axios
    .get(`${GET_CRM_CLIENTS_URL}?${query}`)
    .then((response: AxiosResponse<LeadQueryResponse>) => response.data)

  else {
    return axios
      .get(`${GET_CRM_CLIENTS_URL}?${query}&sellerId=${sellerId}`)
      .then((response: AxiosResponse<LeadQueryResponse>) => response.data)
  }
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

const assignSelectedUsers = (userIds: Array<ID>, selectedUser: number): Promise<void> => {
  const requests = userIds.map((id) => axios.put(`${API_CRM_URL}/clients/updateStatusById?id=${id}`, {
    seller: selectedUser
  }))
  return axios.all(requests).then(() => { })
}

const getSelectedUsers = (): Promise<IUser[] | undefined> => {
  return axios
    .get(GET_USERS_URL)
    .then((response: AxiosResponse<Response<IUser[]>>) => response.data)
    .then((response: Response<IUser[]>) => response.data)
}

const getPhaselead = (): Promise<IPhaselead[] | undefined> => {
  return axios
    .get(GET_PHASE_LEADS)
    .then((response: AxiosResponse<Response<IPhaselead[]>>) => response.data)
    .then((response: Response<IPhaselead[]>) => response.data)
}

export { GET_CRM_CLIENTS, CREATE_CRM_LEAD, POST_COMMENTS, getSelectedUsers, deleteSelectedUsers, getPhaselead, assignSelectedUsers }
