import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty } from '../../../../../../_metronic/helpers'
import { ILead, IUser, IPhaselead, IEstados } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { CREATE_CRM_LEAD, getPhaselead, getSelectedUsers } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { ClientsListLoading } from '../../../client-management/clients-list/components/loading/ClientsListLoading'
import { useIntl } from 'react-intl'

type Props = {
  isUserLoading: boolean
  lead: ILead
}

const editLeadSchema = Yup.object().shape({
  corporate_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Corporate name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  origin: Yup.string()
    .min(2, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Origin is required'),
  phone: Yup.string().min(11, 'Minimum 11 symbols').required('Phone is required'),
})

const LeadEditModalForm: FC<Props> = ({ lead, isUserLoading }) => {
  const intl = useIntl()
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [userForEdit] = useState<ILead>({
    ...lead,
    phase_lead: lead.phase_lead,
    corporate_name: lead.corporate_name,
    origin: lead.origin,
    phone: lead.phone,
    email: lead.email,
    seller: lead.seller,
    campain: lead.campain,
  })
  const [selectUsers, setSelectUsers] = useState<IUser[] | undefined>(undefined)
  const [phaseLead, setPhaseLead] = useState<IPhaselead[] | undefined>(undefined)


  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editLeadSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      try {
        if (isNotEmpty(values.corporate_name)) {
          await CREATE_CRM_LEAD(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  async function selectUsersOption() {
    const response = await getSelectedUsers()
    if (!response) return null
    setSelectUsers(response)
  }

  async function selectPhaseLeadOptions() {
    const response = await getPhaselead()
    if (!response) return null
    setPhaseLead(response)
  }

  useEffect(() => {
    selectUsersOption()
    selectPhaseLeadOptions()
  }, [])


  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* NOME DA EMPRESA - ORIGEM  */}
          <div className='row'>
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({ id: 'PAGE.NAME_COMPNAY' })}
              </label>

              {/* {intl.formatMessage({ id: 'PAGE.PHASE_LEAD' })} */}
              <input
                placeholder={intl.formatMessage({ id: 'PAGE.NAME_COMPNAY' })}
                {...formik.getFieldProps('corporate_name')}
                type='text'
                name='corporate_name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.corporate_name && formik.errors.corporate_name },
                  {
                    'is-valid': formik.touched.corporate_name && !formik.errors.corporate_name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.corporate_name && formik.errors.corporate_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.corporate_name}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-md-6'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({ id: 'CLIENTE.ORIGEN' })}
              </label>
              <input
                placeholder={intl.formatMessage({ id: 'CLIENTE.ORIGEN' })}
                {...formik.getFieldProps('origin')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.origin && formik.errors.origin },
                  {
                    'is-valid': formik.touched.origin && !formik.errors.origin,
                  }
                )}
                type='email'
                name='origin'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.origin && formik.errors.origin && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.origin}</span>
                </div>
              )}
            </div>
          </div>

          {/* EMAL TELEFONE  */}
          <div className='row'>
            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({ id: 'CLIENTE.EMAIL' })}
              </label>
              <input
                placeholder={intl.formatMessage({ id: 'CLIENTE.EMAIL' })}
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>

            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({ id: 'CLIENTE.TELEFONE' })}
              </label>
              <input
                placeholder={intl.formatMessage({ id: 'CLIENTE.TELEFONE' })}
                {...formik.getFieldProps('phone')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.phone && formik.errors.phone },
                  {
                    'is-valid': formik.touched.phone && !formik.errors.phone,
                  }
                )}
                type='text'
                name='phone'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.phone}</span>
                </div>
              )}
            </div>
          </div>

          {/* RESPONSÁVEL - CAMPANHA  */}
          <div className='row'>
            <div className='col-md-6 mb-7'>
              <label className='form-label fw-bold'>
                {intl.formatMessage({ id: 'PAGE.RESPONSIBLE' })}
              </label>

              <div>
                <select
                  className='form-select form-select-solid form-select-md'
                  {...formik.getFieldProps('sellerId')}
                  defaultValue='1'
                >
                  <option>{intl.formatMessage({ id: 'PAGE.SELECT.RESPONSIBLE' })}</option>
                  {selectUsers &&
                    selectUsers?.map((user, index) => (
                      <option key={index} value={String(user.id)}>
                        {user.first_name + ' ' + user.username}
                      </option>
                    ))}
                </select>
                {formik.touched.seller && formik.errors.seller && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.seller}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='col-md-6 mb-7'>
              <label className='required fw-bold fs-6 mb-2'>
                {intl.formatMessage({ id: 'CLIENTE.CAMPANHA' })}
              </label>
              <input
                placeholder={intl.formatMessage({ id: 'CLIENTE.CAMPANHA' })}
                {...formik.getFieldProps('campain')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.campain && formik.errors.campain },
                  {
                    'is-valid': formik.touched.campain && !formik.errors.campain,
                  }
                )}
                type='text'
                name='campain'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.campain && formik.errors.campain && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.campain}</span>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({ id: 'PAGE.PHASE_LEAD' })}
            </label>
            <select
              className='form-select form-select-solid form-select-md'
              {...formik.getFieldProps('phase_lead')}
            >
              <option>{intl.formatMessage({ id: 'PAGE.PHASE_LEAD' })}</option>
              {phaseLead &&
                phaseLead?.map((phase, index) => (
                  <option key={index} value={String(phase.id)}>
                    {phase.phase}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({ id: 'PAGE.BUTTON.DISCARD' })}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
          // disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {intl.formatMessage({ id: 'PAGE.BUTTON.CREATE' })}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <ClientsListLoading />}
    </>
  )
}

export { LeadEditModalForm }
